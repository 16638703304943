@use '../../Assets/css/var';

*,
*::before,
*::after {
    box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

@font-face {
    font-family: 'Nunito';
    src: url('../fonts/Nunito.eot');
    src: url('../fonts/Nunito.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Nunito.woff2') format('woff2'),
        url('../fonts/Nunito.woff') format('woff'),
        url('../fonts/Nunito.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: "oxchange";
    src: url('../fonts/oxchange.eot');
    src: url('../fonts/oxchange.eot?#iefix') format('embedded-opentype'),
        url('../fonts/oxchange.woff2') format('woff2'),
        url('../fonts/oxchange.woff') format('woff'),
        url('../fonts/oxchange.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    color: var.$colour-white;
    text-align: left;
    background-color: #000000;
    word-break: break-word;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-image: url(../images/bgglass.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -2;
        opacity: 0;
    }

    &::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../images/backgif.gif);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -3;
        filter: hue-rotate(310deg) brightness(0.2);
    }
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: var.$colour-theme-dark;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: var.$colour-theme;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background-color: var.$colour-theme-dark;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'oxchange';
    line-height: 1;
}

img {
    max-width: 100%;
    height: auto;
}

section {
    margin-bottom: 100px;
    position: relative;

    section {
        margin-bottom: 0px;
    }
}

a {
    color: var.$colour-white;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: var.$colour-theme;
    }
}

.container {
    max-width: 1320px;
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
}

.title {
    text-align: center;
    padding: 0;
    margin: 0px 0px 40px;
    border: none;

    h2 {
        text-align: center;
        display: block;
        margin: 0px;
        color: var.$colour-theme;
        font-size: 28px;
        text-transform: capitalize;
    }
}

.buttonGrp {
    text-align: left;
    margin-top: 40px;
    margin-left: -7.5px;
    margin-right: -7.5px;

    .fullwidthBtn {
        width: 100%;
        max-width: 436px;
    }

}

.oc_btn {
    padding: 15px 20px;
    border-radius: 5px;
    margin: 0 7.5px 15px;
    min-width: 180px;
    transition: all 0.2s ease-in-out;
    position: relative;
    background-color: var.$colour-transparent;
    color: var.$colour-white;
    border: 1px solid var.$colour-theme-dark;
    font-weight: 400;
    display: inline-block;
    text-align: center;

    &:hover {
        opacity: 0.8;
    }

    &:nth-child(1) {
        &::before {
            content: "";
            position: absolute;
            top: -9px;
            left: -5px;
            background-image: url('../images/buttonBorderTopLeft.svg');
            background-position: top left;
            background-repeat: no-repeat;
            background-size: contain;
            width: 110px;
            height: 60px;
        }
    }

    &:nth-last-child(1) {
        &::after {
            content: "";
            position: absolute;
            bottom: -9px;
            right: -5px;
            background-image: url('../images/buttonBorderTopLeft.svg');
            background-position: top left;
            background-repeat: no-repeat;
            background-size: contain;
            width: 110px;
            height: 60px;
            transform: scale(-1);
        }
    }

    &.ocPublicBrn {
        animation: blinkOcPublicBrn 1.5s infinite;
        transition: all 0.3s ease-in-out;
        box-shadow: none;
        text-shadow: none;
    }

    @keyframes blinkOcPublicBrn {
        0% {
            border-color: #125d60;
            opacity: 1;
            box-shadow: none;
            text-shadow: none;
        }

        49% {
            border-color: #02ffff;
            color: #02ffff;
            text-shadow:
                -0.2rem -0.2rem 1rem #ffffff,
                0.2rem 0.2rem 1rem #ffffff,
                0 0 2rem #02ffff,
                0 0 4rem #02ffff,
                0 0 6rem #02ffff,
                0 0 8rem #02ffff,
                0 0 10rem #02ffff;
            box-shadow:
                0 0 .1rem #125d60,
                inset 0 0 .1rem #125d60,
                0 0 2rem #125d60,
                inset 0 0 0rem #125d60,
                0 0 1rem #125d60,
                inset 0 0 1rem #02ffff;

        }

        50% {
            border-color: #125d60;
            opacity: 1;
            box-shadow: none;
            text-shadow: none;

        }
    }
}

// Title

.titlebox {
    text-align: center;
    max-width: 600px;
    margin: 0px auto 40px;

    h6 {
        color: var.$colour-theme;
        margin-bottom: 15px;
        font-size: 20px;
    }

    h2 {
        font-size: 62px;
        margin-bottom: 15px;
        line-height: 1;
    }
}

// Social Media
.socialIcons {
    margin-top: 30px;
    margin-bottom: 20px;

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;

        li {
            margin: 0px 8px;

            a {
                width: 40px;
                height: 40px;
                border-radius: 50px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                border: 1px solid var.$colour-theme-dark;
            }

            svg {
                fill: var.$colour-theme;
                width: 100%;
                height: 100%;
                object-fit: contain;
                padding: 10px;
            }
        }
    }
}

.main {
    &>section {
        &:nth-child(even) {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background-image: url('../images/secleftimg.png');
                background-position: left top;
                background-repeat: no-repeat;
                background-size: contain;
                width: 30vw;
                height: 30vw;
                z-index: -1;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                background-image: url('../images/secrightimg.png');
                background-position: right bottom;
                background-repeat: no-repeat;
                background-size: contain;
                width: 30vw;
                height: 30vw;
                z-index: -1;
            }
        }
    }
}

#my-scrollbar {
    width: 500px;
    height: 500px;
    overflow: auto;
}

.chatOcMain {
    .chatOc {
        max-height: 850px
    }

    .tokenomicsTable {
        max-width: 800px;
        margin: 0 auto;
        background-color: #26282c;
        padding: 30px 30px 10px;
        border-radius: 7px;

        table {
            width: 100%;

            .oc_btn_cst {
                text-align: center;
                display: inline-block;
                border: 1px solid #92b5bc;
                padding: 6px 15px;
                border-radius: 4px;
                color: #ffffff;
                background-color: #206977;
                margin-left: 15px;
                margin-right: -5px;
                margin-top: 10px;
                margin-bottom: 5px;
            }

            th {
                padding: 10px 15px;
                background-color: #373a40;
                font-weight: 500;
                font-size: 20px;
                text-align: center;
            }

            td {
                padding: 8px 15px;
                font-weight: 500;
                font-size: 16px;
                border-bottom: 1px solid #373a40;
            }

            tr {
                &:nth-last-child(1) {
                    td {
                        border: none;
                    }
                }

                td:nth-child(even) {
                    text-align: right
                }

            }

        }
    }
}

// Media Query Css

@import 'media';