.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    padding: 20px;
}

.popupimg {
    width: 100%;
    max-width: 420px;
    display: block;
    margin: 20px auto;
}

.descpopup {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.3;
}

.popup-content {
    background: #0c0b0b;
    padding: 35px 20px 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    color: #ffffff;
    width: 100%;
    max-width: 750px;
    border: 1px solid #00ffff;
    max-height: 98vh;
    overflow: auto;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 15px;
    cursor: pointer;
    color: #ffffff;
    font-weight: bold;
    font-size: 30px;
}

.link-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #00ffff !important;
    color: #000000 !important;
    text-decoration: none;
    border-radius: 4px;
    outline: none !important;
    box-shadow: none;
    font-weight: bold;
    font-size: 20px;
    border: 1px solid #00bdbd;
    min-width: 180px;
}

.appdownloadBtn {
    padding: 0px;
    margin: 30px auto 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.appdownloadBtn li {
    list-style: none;
}

.downloadnowbtn {
    padding: 0px;
    margin: 30px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.downloadnowbtn li {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 18px;
    font-weight: 500;
}

.downloadnowbtn li img {
    max-width: 30px;
}

@media only screen and (max-width: 767px) {
    .popup-content .popupimg {
        max-width: 250px;
        margin-bottom: 10px;
    }

    .popup-content .descpopup {
        font-weight: 500;
        font-size: 14px;
        line-height: 1.1;
    }

    .popup-content .downloadnowbtn {
        margin: 12px auto 10px;
    }

    .popup-content .appdownloadBtn {
        margin: 15px auto 10px;
    }

    .popup-content {
        padding: 25px 15px 10px;
    }
}