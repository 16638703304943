@use '../../Assets/css/var';

.features {
    .imageBox {
        img {
            max-width: 100%;
            margin: 0 auto;
            border-radius: 15px;
        }
    }

    .leftinfoFaq {
        b {
            color: var.$colour-theme;
            display: inline-block;
            margin-right: 5px;
            font-weight: 400;
        }
    }
}