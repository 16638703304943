@use '../../Assets/css/var';

header {
    position: relative;
    filter: drop-shadow(0px -60px 60px #02ffff);
    top: 0px;
    z-index: 4;
    transition: all 0.3s ease-in-out;

    &.openmenu {
        position: sticky;
    }

    .clipHeader {
        position: relative;
        padding-top: 10px;
        padding-bottom: 5px;
        background: #02ffff;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 165px) calc(100% - 40px), calc(100% - 185px) calc(100% - 20px), calc(100% - 320px) calc(100% - 20px), calc(100% - 340px) calc(100% - 40px), 360px calc(100% - 40px), 320px 100%, 0 100%, 0 0);
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 165px) calc(100% - 40px), calc(100% - 185px) calc(100% - 20px), calc(100% - 320px) calc(100% - 20px), calc(100% - 340px) calc(100% - 40px), 360px calc(100% - 40px), 320px 100%, 0 100%, 0 0);

        &::before {
            content: "";
            position: absolute;
            background-color: #000000;
            -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 165px) calc(100% - 40px), calc(100% - 185px) calc(100% - 20px), calc(100% - 320px) calc(100% - 20px), calc(100% - 340px) calc(100% - 40px), 360px calc(100% - 40px), 460px 100%, 140px 100%, 130px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 165px) calc(100% - 40px), calc(100% - 185px) calc(100% - 20px), calc(100% - 320px) calc(100% - 20px), calc(100% - 340px) calc(100% - 40px), 360px calc(100% - 40px), 460px 100%, 140px 100%, 130px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0);
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
            z-index: -1;
            background-image: url(../../Assets/images/backgif.gif);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            filter: hue-rotate(310deg) brightness(0.3);
        }
    }

    .container {
        max-width: calc(100% - 330px);
    }

    .before {
        position: absolute;
        bottom: 12px;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        height: 12px;
        width: 470px;
        transform: skewX(-45deg);

        span {
            background-color: var.$colour-theme;
            width: 20px;
            height: 12px;
            text-decoration: blink;
            animation-name: blinker;
            animation-duration: 0.6s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
            animation-direction: alternate;
            border: 1px solid transparent;
        }

        @keyframes blinker {
            from {
                border: 1px solid transparent;
            }

            to {
                border: 1px solid var.$colour-theme;
                background-color: var.$colour-theme-dark;
            }
        }
    }
}

.logo {
    img {
        width: 100%;
        max-width: 110px;
    }
}

.menu {
    position: relative;
    bottom: 20px;

    li {
        display: inline-block;
        margin: 0px 25px;
    }

    a {
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 2px;
        font-weight: 600;
        cursor: pointer;
    }
}

.balancebox {
    display: flex;
    align-items: center;

    li {
        display: inline-block;

        &:nth-last-child(1) {
            margin-left: 10px;
        }

        span {
            padding: 10px 20px;
            border-radius: 4px;
            cursor: pointer;
            color: var.$colour-white;
            border: 1px solid var.$colour-theme;
            font-weight: 600;
            letter-spacing: 2px;
            position: relative;
            min-width: auto;
            margin: 0px;

            &::before {
                width: 100px !important;
            }

            &::after {
                width: 100px !important;
            }
        }

        strong {
            margin-left: 8px;
        }
    }
}

.rightboxmenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    bottom: 15px;
}

.hamburger {
    cursor: pointer;
    width: 35px !important;
    height: 26px;
    position: relative;
    margin-left: 15px;
    z-index: 1;

    span {
        cursor: pointer;
        border-radius: 1px;
        height: 3px;
        background-color: #02ffff;
        display: inline-block;
        content: "";
        transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
        position: absolute;
        top: 10px;
        left: 0px;
        width: 100%;

        &:before {
            cursor: pointer;
            border-radius: 1px;
            height: 3px;
            width: 100%;
            background-color: var.$colour-theme;
            position: absolute;
            display: inline-block;
            content: '';
            top: -10px;
            left: 0px;
            transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
        }

        &:after {
            cursor: pointer;
            border-radius: 1px;
            height: 3px;
            width: 100%;
            background-color: var.$colour-theme;
            position: absolute;
            display: inline-block;
            content: '';
            bottom: -10px;
            left: 0px;
            transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
        }
    }
}

.hamburger.is-active {
    span {
        background-color: transparent;

        &:before {
            top: 0;
            transform: rotate(45deg);
        }

        &:after {
            top: 0;
            transform: translateY(-10px) rotate(-45deg);
            top: 10px;
        }
    }
}

.menubar {
    position: fixed;
    top: 0;
    left: -360px;
    width: 0px;
    height: 100%;
    z-index: 3;
    background-color: #041516;
    display: block !important;
    box-shadow: -20px 0px 30px 0px #000000;
    transition: all 0.3s ease-in-out;
    padding-top: 140px;
    padding-left: 20px;

    .menu {
        display: block;
        padding-top: 30px !important;

        li {
            display: block;
            margin-bottom: 10px;

            a {
                display: block;
                text-align: left;
                padding: 10px;
                font-size: 20px;
            }
        }
    }
}


.mobileMenu {
    left: 0;
    width: 100%;
}


@media only screen and (max-width: 1440px) {
    .menu li {
        margin: 0px 10px;
    }

    .menu a {
        font-size: 14px;
        letter-spacing: 0px;
        font-weight: 500;
    }

    header .container {
        max-width: calc(100% - 0px);
    }

    header .clipHeader {
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 15px) calc(100% - 20px), calc(100% - 170px) calc(100% - 20px), calc(100% - 190px) calc(100% - 40px), 180px calc(100% - 40px), 150px 100%, 0 100%, 0 0);

        &::before {
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 15px) calc(100% - 20px), calc(100% - 170px) calc(100% - 20px), calc(100% - 190px) calc(100% - 40px), 180px calc(100% - 40px), 150px 100%, 10px 100%, 2px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0)
        }
    }

    header .before {
        width: 300px;
    }
}

@media only screen and (max-width: 992px) {
    .balancebox li span {
        padding: 10px 15px;
        letter-spacing: 1px;
        margin: 0px;
    }
}

@media only screen and (max-width: 767px) {


    header .clipHeader {
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 0px) calc(100% - 40px), calc(100% - 0px) calc(100% - 0px), calc(100% - 0px) calc(100% - 40px), 180px calc(100% - 40px), 140px 100%, 0 100%, 0 0);

        &::before {
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 0px) calc(100% - 40px), calc(100% - 0px) calc(100% - 0px), calc(100% - 0px) calc(100% - 40px), 180px calc(100% - 40px), 140px 100%, 0px 100%, 2px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0);
        }
    }

    .rightboxmenu {
        bottom: 0px;
        margin-bottom: 50px;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 992px) {
    .menu li {
        margin: 0px 10px;
    }

    .logo img {
        width: 100%;
        max-width: 110px;
    }

    // .balancebox li {
    //     font-size: 12px;
    // }
}

@media only screen and (max-width: 390px) {
    .balancebox {
        font-size: 14px;
    }

    .balancebox li span {
        padding: 8px 10px;
    }

    .hamburger {
        width: 30px !important;
        height: 23px;
        margin-left: 10px;
    }
}