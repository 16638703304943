@use '../../Assets/css/var';

.ox_banner {
    margin: 0px 0px 120px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 50px;

    .infobox {
        margin-bottom: 15px;

        h1 {
            margin: 0 0 30px;
            font-size: 62px;
            font-weight: bold;
            line-height: 1;
        }

        ul {
            padding: 0px;
            margin: 0px 0px 30px;
            list-style: none;

            li {
                margin-bottom: 15px;
            }
        }

        .appdownloadBtn {
            max-width: 425px;
            margin: 10px 0px;
            padding: 0px;

            li {
                margin: 0px;
            }
        }

        .downloadnowbtn {
            max-width: 425px;
            margin: 10px 0px 20px;
            padding: 0px;

            li {
                list-style: none;
                display: flex;
                align-items: center;
                gap: 7px;
                font-size: 18px;
                font-weight: 500;
                margin: 0px;
            }
            img {
                max-width: 30px;
            }
        }


        h3 {
            font-size: 20px;
            margin: 0 0 10px;
        }

        .quickPlayTitle {
            margin: 40px 0px 30px;
            font-size: 30px;
        }

        .buttonGrp.headGrpBtn {
            margin-top: 5px;

            .oc_btn {
                min-width: 210px;
            }
        }
    }

    .imageBox {
        position: relative;
        max-width: 540px;
        margin: 0 auto;
        text-align: center;

        svg {
            max-width: 100%;
        }

        .mainImg {
            width: 100%;
            border-radius: 30px 0px 30px 0px;
            padding: 50px;
        }

        // Dashboard Css Start
        .cirLeftToRight {
            transform-origin: center;
            animation: keyLeftToRight 5s ease-in-out infinite alternate-reverse;
        }

        .cirLeftToRightR {
            transform-origin: center;
            animation: keyLeftToRight 10s ease-in-out infinite alternate;
        }

        .cirLeftToRightRS {
            transform-origin: center;
            animation: keyLeftToRight 20s ease-in-out infinite alternate;
        }

        @keyframes keyLeftToRight {
            to {
                transform: rotate(360deg);
            }
        }

        .cirRightToLeft {
            transform-origin: center;
            animation: keyRightToLeft 15s linear infinite;
        }

        @keyframes keyRightToLeft {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .righttoleft {
            transform-origin: center;
            animation: righttoleft 6s linear infinite reverse;
        }

        @keyframes righttoleft {
            to {
                transform: rotate(-360deg);
            }
        }

        .lefttoright {
            transform-origin: center;
            animation: righttoleft 6s linear infinite;
        }

        @keyframes lefttoright {
            to {
                transform: rotate(-360deg);
            }
        }

        .circleLine {
            position: absolute;
            bottom: -30px;
            left: -30px;
            width: 90px;
            height: 90px;
            background-color: #182133;
            border-radius: 50%;
            animation: circleLine 12s linear infinite;

        }

        @keyframes circleLine {
            to {
                transform: rotate(360deg);
            }
        }

        .ocChangeIcon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 160px;
        }
    }

    .countDownMuMain {
        text-align: center;
        margin-top: 20px;

        h2 {
            font-size: 32px;
        }

        .countDownMu {
            display: inline-block;
            padding: 6px 10px;
            font-size: 26px;
            border-radius: 4px;
            min-width: 220px;
            margin-top: 10px;
            border: 1px solid #01ffff;
            color: #01ffff;

            b {
                display: inline-block;
                margin: 0px 5px;
            }
        }
    }
}

.smartContractBx {
    display: flex;
    align-items: center;

    label {
        display: block;
        margin-right: 10px;
    }

    a {
        display: inline-block;
        color: #01ffff !important;
        font-size: 16px;
        text-decoration: underline;
        font-weight: 600;
        letter-spacing: 1px;
    }

    img {
        width: auto;
        filter: invert(1);
        margin-left: 10px;
        height: 20px;
        max-width: 20px;
        cursor: pointer;
    }
}

@media only screen and (max-width: 767px) {
    .ox_banner {
        text-align: center;
        padding-top: 30px;
    }

    .smartContractBx {
        justify-content: center;
    }

    .ox_banner .infobox ul {
        justify-content: center;
    }

    .ox_banner .imageBox svg {
        max-height: 33vh;
        width: 90vw;
    }

    .ox_banner .imageBox .ocChangeIcon {
        max-width: 100px;
    }
}

@media only screen and (max-width: 575px) {
    .smartContractBx a {
        font-size: 14px;
        letter-spacing: 0.5px;
    }

    .smartContractBx {
        font-size: 16px;
    }

    .smartContractBx img {
        height: 18px;
        max-width: 18px;
    }
}