@media only screen and (max-width: 1280px) {
  .headGrpBtn {
    .oc_btn {
      font-size: 14px;
      min-width: 100px;
      margin: 0 5px 15px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .oc_btn {
    padding: 10px 10px;
    min-width: 130px;
    font-size: 14px;
  }

  .oc_btn:nth-child(1)::before {
    width: 90px;
    height: 50px;
  }

  .oc_btn:nth-last-child(1)::after {
    width: 90px;
    height: 50px;
  }

  .ox_banner .infobox h1 {
    font-size: 42px;
  }

  .headGrpBtn {
    display: block;
    max-width: 320px;

    .oc_btn {
      width: 100%;
      margin: 0px 0px 20px;
      padding: 15px 8px;
      font-size: 16px;
    }
  }

  .ox_banner .infobox .downloadnowbtn {
    max-width: 315px;
  }

  .ox_banner .infobox .appdownloadBtn {
    max-width: 305px;
  }
}

@media only screen and (max-width: 767px) {
  .buttonGrp {
    text-align: center;
    margin-top: 30px;
  }

  .container {
    max-width: calc(100% - 15px);
  }

  .ox_banner .infobox h1 {
    font-size: 36px;
  }

  .titlebox h2 {
    font-size: 36px;
  }

  .aboutsec .imageBox img,
  .features .imageBox img {
    max-width: 100%;
  }

  .headGrpBtn {
    margin-left: auto;
    margin-right: auto;
  }

  .ox_banner {
    margin: 0px 0px 50px;
  }

  section {
    margin-bottom: 50px;
  }

  .ox_banner .infobox .downloadnowbtn {
    margin-left: auto;
    margin-right: auto;
  }

  .ox_banner .infobox .appdownloadBtn {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 575px) {
  .balancebox li span {
    width: 100%;
    display: block;
    padding: 8px 6px;
  }

  .balancebox li:nth-last-child(1) {
    margin-left: 6px;
  }

  .hamburger {
    width: 25px !important;
  }

  .chatOcMain .tokenomicsTable {
    padding: 0px;
  }

  .chatOcMain .tokenomicsTable table td {
    padding: 8px 10px;
    font-size: 14px;
    line-height: 1.2;
  }

  .chatOcMain .tokenomicsTable table {
    tr {
      &:nth-last-child(1) {
        text-align: center;

        td {
          display: revert;

          button {
            text-align: center;
            padding: 10px 5px;
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 3px;
            margin-bottom: 3px;
            width: 100%;
            font-size: 16px;
            max-width: 150px;
          }
        }
      }
    }
  }
}