// Footer Css Start
footer {
    text-align: center;
    padding: 30px 15px;
    background-image: linear-gradient(transparent, #00090d);

    .copyright {
        text-align: center;

        p {
            margin: 0px;
            font-size: 14px;
        }
    }

    .socialIcons {
        ul {
            list-style: none;
            justify-content: center;
        }
    }
}

@media only screen and (max-width: 575px) {
    footer ul li {
        margin: 0px 3px;
    }

    footer ul li a {
        width: 35px;
        height: 35px;
    }

    footer ul li svg {
        padding: 7px;
    }

    footer .socialIcons ul {
        padding: 0;
        margin: 0px;
    }

    footer .socialIcons ul li {
        margin: 0 4px 8px;
    }

    footer .socialIcons ul li a {
        height: 32px;
        width: 32px;
    }

    footer .socialIcons ul li svg {
        padding: 7px;
    }
    footer .socialIcons {
        margin-bottom: 12px;
    }
}

// Footer Css End