@use '../../Assets/css/var';

.faqs {
    margin: 0 auto 60px;

    .faq-row-wrapper {
        position: relative;
        padding: 40px;

        &::before {
            content: "";
            position: absolute;
            top: -4px;
            right: -2px;
            background-image: url('../../Assets/images/b2tr.svg');
            background-position: top right;
            background-repeat: no-repeat;
            background-size: contain;
            width: 250px;
            height: 110px;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: -4px;
            left: -2px;
            background-image: url('../../Assets/images/b2bl.svg');
            background-position: top left;
            background-repeat: no-repeat;
            background-size: contain;
            width: 250px;
            height: 110px;
        }

        .faq-body {
            padding: 50px;
            border: 1px solid var.$colour-theme;
            border-radius: 25px;
        }

        .faq-title {
            text-align: center;
            justify-content: center;
            padding: 0;
            margin: 0px 0px 40px;
            border: none;

            h2 {
                font-size: 28px;
            }
        }

        .faq-body {
            .faq-row {
                // border: 1px solid var.$colour-theme-dark;
                margin-bottom: 5px;
                padding: 0px;
                border: none;

                .row-title {
                    padding: 10px 40px 10px 0px;
                }

                .icon-wrapper {
                    top: 10px !important;
                    right: 0px !important;
                }

                .row-content-text {
                    color: var.$colour-white !important;
                    font-size: 14px !important;
                    opacity: 0.5;
                    padding-right: 40px !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .faqs .faq-row-wrapper {
        padding: 0px;
    }

    .faqs .faq-row-wrapper .faq-body {
        padding: 15px 10px 15px 20px;
    }

    .faqs .faq-row-wrapper::before,
    .faqs .faq-row-wrapper::after {
        display: none;
    }
    .faqs .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY {
        font-size: 16px;
    }
    .faqs .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {
        padding-right: 0px;
    }
}